import { Button, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";

import EditBeaconDialog from "./EditBeaconDialog";

function GeneralCard({ beacon }: { beacon: WithDocRef<Beacon> }): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const beaconKey = beacon.key.match(/.{3}/g)?.join("\u00B7") ?? beacon.key;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (beacon.timestamps.created_at as Timestamp)?.toDate();
  const updatedAt = (beacon.timestamps.updated_at as Timestamp)?.toDate();
  const lastSeen = (beacon.timestamps.last_seen as Timestamp)?.toDate();

  return (
    <>
      <PartitionedCard
        title={"General"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"License key"}>
          <Typography fontFamily={"monospace"}>{beaconKey}</Typography>
        </PartitionedCardItem>

        <PartitionedCardItem title={"First created"}>
          {createdAt ? (
            <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Last updated"}>
          {updatedAt ? (
            <Tooltip title={format(updatedAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(updatedAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Last seen"}>
          {lastSeen ? (
            <Tooltip title={format(lastSeen, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(lastSeen, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"App version"}>
          <Typography>{beacon.last_user_agent || "None"}</Typography>
        </PartitionedCardItem>
      </PartitionedCard>

      {openEditDialog && (
        <EditBeaconDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          beacon={beacon}
        />
      )}
    </>
  );
}

export default GeneralCard;
