import { Divider, Stack, TextField } from "@mui/material";
import { deleteField, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";

function EditStationDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        name: station.name,
        nrel_id: station.nrel_id ?? "",
        network_station_id: station.network_station_id ?? "",
      });
    }
  }, [open, reset, station]);

  const onSubmit = async (data: any) => {
    try {
      const nrelIdChanged = (() => {
        if (!data.nrel_id && !station.nrel_id) {
          return false; // Both empty
        }
        return data.nrel_id !== station.nrel_id;
      })();

      await updateDoc(station.docRef, {
        name: data.name.trim(),
        nrel_id: data.nrel_id.trim() || null,
        ...(nrelIdChanged ? { khloud_guid: deleteField() } : {}),
        network_station_id: data.network_station_id.trim() || null,
        ...updatedAt,
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Station"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />

        <Divider />

        <Controller
          name={"nrel_id"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="NREL ID"
              helperText={error?.message}
              error={Boolean(error)}
              disabled={Object.values(station.children || {}).length > 0}
              fullWidth
            />
          )}
        />

        <Controller
          name={"network_station_id"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Network ID"
              helperText={error?.message}
              error={Boolean(error)}
              disabled={Object.values(station.children || {}).length > 0}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditStationDialog;
