import { Star, StarOutline } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import { cw } from "../../theme/variants";
import type { StationPhoto } from "../../types/station-photo.db";
import type { Station } from "../../types/station.db";
import type { User } from "../../types/user.db";
import { updatedAt } from "../../util/firebase";

function PhotoListItem({
  photo,
  users,
}: {
  photo: StationPhoto;
  users: User[];
}): JSX.Element {
  const navigate = useNavigate();
  const isFeatured = useMemo(
    () => photo.featured === true || photo.sort_order === 0,
    [photo]
  );
  const user = users.find((u) => u.id === photo.user_id);
  const phoneNumber = user ? parsePhoneNumber(user.phone ?? "") : null;
  const createdAt = (photo.timestamps.created_at as Timestamp)?.toDate();

  const navigateOnClick = useCallback(
    (event) => {
      const _path = `/photos/${photo.id}`;
      if (event.metaKey) {
        window.open(_path, "_blank");
      } else {
        navigate(_path);
      }
    },
    [photo.id, navigate]
  );

  const updateFeatured = async (isFeatured: boolean) => {
    try {
      const db = getFirestore();
      const collectionRef = collection(db, "station-photos");
      const snapshot = await getDocs(
        query(collectionRef, where("station_id", "==", photo.station_id))
      );
      const batch = writeBatch(db);
      for (const doc of snapshot.docs) {
        if (doc.id === photo.id) {
          batch.update(doc.ref, {
            featured: isFeatured,
            sort_order: isFeatured ? 0 : 99,
            ...updatedAt,
          });
        } else {
          batch.update(doc.ref, {
            featured: false,
            sort_order: 99,
            ...updatedAt,
          });
        }
      }
      await batch.commit();
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <ImageListItem key={photo.id}>
      <img
        src={photo.photo_url}
        alt={photo.id}
        onClick={navigateOnClick}
        style={{ cursor: "pointer" }}
        loading="lazy"
      />

      {user && (
        <ImageListItemBar
          title={
            <Link href={`/users/${user.id}`} sx={{ color: "text.primary" }}>
              {user.name ?? user.email ?? phoneNumber?.formatNational()}
            </Link>
          }
          subtitle={formatDistanceToNowStrict(createdAt, { addSuffix: true })}
          actionIcon={
            <Checkbox
              checked={isFeatured}
              onChange={(event) => {
                updateFeatured(event.target.checked);
              }}
              icon={<StarOutline />}
              checkedIcon={<Star />}
              sx={{
                "&.Mui-checked": {
                  color: cw.orange["500"],
                },
              }}
            />
          }
        />
      )}
    </ImageListItem>
  );
}

const listReducer = (
  state: User[],
  action: { type: "add"; newItem: User }
): User[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function PhotoMosaicCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database: db } = useFirebase();
  const [items, loading] = useQuery<StationPhoto>(
    query(
      collection(db, "station-photos"),
      where("station_id", "==", station.id),
      orderBy("timestamps.created_at", "desc"),
      limit(250) // Temporary until pagination is implemented
    )
  );
  const [users, dispatch] = useReducer(listReducer, []);

  // Load users
  useEffect(() => {
    const unique = [...new Set(items.map((item) => item.user_id))];
    for (const userId of unique) {
      getDoc(doc(db, "users", userId)).then((doc) => {
        if (doc.exists()) {
          const user = { id: doc.id, ...doc.data() } as User;
          dispatch({ type: "add", newItem: user });
        }
      });
    }
  }, [db, items]);

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          station.photo_count > 1
            ? `Photos (${numberFormatter.format(station.photo_count)})`
            : "Photos"
        }
      />
      {items.length > 0 && (
        <CardContent>
          <ImageList variant="masonry" cols={fullScreen ? 1 : 3} gap={2}>
            {items.map((item) => (
              <PhotoListItem key={item.id} photo={item} users={users} />
            ))}
          </ImageList>
        </CardContent>
      )}
    </Card>
  );
}

export default PhotoMosaicCard;
