import type { DocumentReference } from "firebase/firestore";
import {
  collection,
  deleteField,
  getDocs,
  getFirestore,
  increment,
  query,
  where,
  writeBatch,
} from "firebase/firestore";

import { updatedAt } from "../../../util/firebase";

/**
 * Move photos and reviews from one station to another.
 *
 * This move is performed in a transaction and also updates the station
 * photo count, review count, and rating.
 *
 * @param from
 * @param to
 */
const movePhotosReviews = async ({
  from,
  to,
}: {
  from: DocumentReference;
  to: DocumentReference;
}) => {
  let snapshot;
  const db = getFirestore();

  // Photos
  const photos: DocumentReference[] = [];
  snapshot = await getDocs(
    query(collection(db, "station-photos"), where("station_id", "==", from.id))
  );
  snapshot.docs.forEach((doc) => photos.push(doc.ref));

  // Reviews
  const reviews: DocumentReference[] = [];
  snapshot = await getDocs(
    query(collection(db, "station-reviews"), where("station_id", "==", from.id))
  );
  snapshot.docs.forEach((doc) => reviews.push(doc.ref));

  // Calculate new rating
  snapshot = await getDocs(
    query(
      collection(db, "station-reviews"),
      where("station_id", "in", [from.id, to.id])
    )
  );

  const ratings: number[] = snapshot.docs.map((doc) => doc.data().rating);
  const average =
    ratings.length > 0 ? ratings.reduce((a, b) => a + b) / ratings.length : 0;
  const formatted = Number(average.toFixed(1));

  const batch = writeBatch(db);
  photos.forEach((docRef) => {
    batch.update(docRef, {
      station_id: to.id,
      khloud_station_id: deleteField(),
      khloud_station_guid: deleteField(),
      ...updatedAt,
    });
  });
  reviews.forEach((docRef) => {
    batch.update(docRef, {
      station_id: to.id,
      khloud_station_id: deleteField(),
      khloud_station_guid: deleteField(),
      ...updatedAt,
    });
  });
  batch.update(from, {
    photo_count: 0,
    review_count: 0,
    rating: 0,
    ...updatedAt,
  });
  batch.update(to, {
    photo_count: increment(photos.length),
    review_count: increment(reviews.length),
    rating: formatted,
    ...updatedAt,
  });
  await batch.commit();
};

export default movePhotosReviews;
