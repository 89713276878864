import {
  Autocomplete,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { connectorNameOptions } from "../../util/chargeway";

import updateStationConnectors from "./firebase/updateStationConnectors";

function EditConnectorDialog({
  open,
  handleClose,
  station,
  selected,
}: {
  open: boolean;
  handleClose: (canceled?: boolean) => void;
  station: WithDocRef<Station>;
  selected: string[];
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const label = useMemo<string>(() => {
    if (selected.length > 1) {
      return ""; // Hidden
    } else {
      const connector = station.connectors[selected[0]];
      return connector.label || "";
    }
  }, [station, selected]);

  const name = useMemo<string | null>(() => {
    if (selected.length > 1) {
      const connectors = Object.values(station.connectors).filter((c) =>
        selected.includes(c.id)
      );
      const first = connectors[0];
      return connectors.every((c) => c.name === first.name) ? first.name : null;
    } else {
      const connector = station.connectors[selected[0]];
      return connector.name;
    }
  }, [station, selected]);

  const power = useMemo<string>(() => {
    let kw: number | null;

    if (selected.length > 1) {
      const connectors = Object.values(station.connectors).filter((c) =>
        selected.includes(c.id)
      );
      const first = connectors[0];
      kw = connectors.every((c) => c.power === first.power)
        ? first.power / 1000
        : null;
    } else {
      const connector = station.connectors[selected[0]];
      kw = connector.power / 1000;
    }

    return kw?.toString() || "";
  }, [station, selected]);

  const status = useMemo<string | null>(() => {
    if (selected.length > 1) {
      const connectors = Object.values(station.connectors).filter((c) =>
        selected.includes(c.id)
      );
      if (connectors.every((c) => c.offline)) {
        return "Offline";
      }
      if (connectors.every((c) => !c.offline)) {
        return "Online";
      }
      return null;
    } else {
      const connector = station.connectors[selected[0]];
      return connector.offline ? "Offline" : "Online";
    }
  }, [station, selected]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        label,
        name,
        power,
        status,
      });
    }
  }, [open, reset, label, name, power, status]);

  const onSubmit = async (data: any) => {
    const watts = Number(data.power) * 1000;

    // Validation
    if (data.name === "J1772") {
      if (watts > 19200) {
        return setError("power", {
          message: "J1772 connectors must not exceed 19.2 kW",
        });
      }
    }

    try {
      await updateStationConnectors({
        station,
        selected,
        label: data.label,
        name: data.name,
        power: watts,
        offline: data.status === "Offline",
      });
      enqueueSnackbar("Success", { variant: "success" });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={
        selected.length > 1
          ? `Edit ${selected.length} connectors`
          : "Edit connector"
      }
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        {selected.length === 1 && (
          <>
            <Controller
              name={"label"}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Label"
                  helperText={error?.message || ""}
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />
            <Divider />
          </>
        )}

        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={connectorNameOptions}
              onChange={(event, newValue) => field.onChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Type"}
                  placeholder={name || ""}
                  helperText={error?.message}
                  error={Boolean(error)}
                />
              )}
              fullWidth
            />
          )}
        />

        <Controller
          name={"power"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Power"
              placeholder={power}
              helperText={error?.message || ""}
              error={Boolean(error)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>kW</InputAdornment>
                ),
              }}
              fullWidth
            />
          )}
        />

        <Controller
          name={"status"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={["Online", "Offline"]}
              onChange={(event, newValue) => field.onChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Status"}
                  helperText={error?.message}
                  error={Boolean(error)}
                />
              )}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditConnectorDialog;
