import { LoadingButton } from "@mui/lab";
import { Alert, Collapse } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";

function AlertNeedsReview({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleDismiss = useCallback(() => {
    setLoading(true);
    updateDoc(station.docRef, {
      needs_review: false,
      ...updatedAt,
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Failed to cancel deletion", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [station, enqueueSnackbar]);

  return (
    <Collapse in={station.needs_review} appear={false}>
      <Alert
        severity={"info"}
        color={"warning"}
        action={
          <LoadingButton
            color={"inherit"}
            onClick={() => handleDismiss()}
            loading={loading}
          >
            Dismiss
          </LoadingButton>
        }
        sx={{ alignItems: "center", marginBottom: 6 }}
      >
        This station has been flagged for review.
      </Alert>
    </Collapse>
  );
}

export default AlertNeedsReview;
