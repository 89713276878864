import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection as firestoreCollection,
  query,
  where,
} from "firebase/firestore";
import React, { useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import useNavigateOnRowClick from "../../hooks/useNavigateOnRowClick";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { formatStationAddress } from "../../util/stdlib";

import RemoveChildDialog from "./RemoveChildDialog";

const renderStationNameAddress = (
  params: GridRenderCellParams<any, Station>
) => {
  return (
    <Stack>
      <Typography>{params.row.name}</Typography>
      <Typography variant={"caption"} color={"text.secondary"}>
        {formatStationAddress(params.row)}
      </Typography>
    </Stack>
  );
};

const renderStatusChip = (params: GridRenderCellParams<any, Station>) => {
  if (params.row.is_coming_soon) {
    return (
      <Chip
        label={"Coming Soon"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (params.row.is_offline) {
    return (
      <Chip
        label={"Offline"}
        variant={"outlined"}
        color={"error"}
        size={"small"}
      />
    );
  }
  return (
    <Chip
      label={"Online"}
      variant={"outlined"}
      color={"success"}
      size={"small"}
    />
  );
};

const renderPublishedChip = (params: GridRenderCellParams<any, Station>) => {
  if (params.row.archived) {
    return (
      <Chip
        label={"Archived"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (!params.row.publish) {
    return (
      <Chip
        label={"Not Published"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (params.row.publish) {
    return (
      <Chip
        label={"Published"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  }
};

const columns: GridColumns<Station> = [
  {
    field: "nrel_id",
    headerName: "NREL ID",
    ...columnTypes.flexDefault,
    minWidth: 100,
  },
  {
    field: "network_station_id",
    headerName: "Network ID",
    ...columnTypes.flexDefault,
  },
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexPrimary,
    minWidth: 350,
    renderCell: renderStationNameAddress,
  },
  {
    field: "state",
    headerName: "State",
    ...columnTypes.flexDefault,
  },
  {
    field: "status",
    headerName: "Status",
    ...columnTypes.flexDefault,
    renderCell: renderStatusChip,
    sortable: false,
  },
  {
    field: "published",
    headerName: "Published",
    ...columnTypes.flexDefault,
    renderCell: renderPublishedChip,
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function ChildrenCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database: db } = useFirebase();
  const collectionRef = firestoreCollection(db, "stations");
  const [items, loading] = useQuery<Station>(
    query(collectionRef, where("parent_id", "==", station.id))
  );
  const handleRowClick = useNavigateOnRowClick("/stations/:id");

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isEmpty = items.length === 0;
  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          items.length > 0
            ? `Children (${numberFormatter.format(items.length)})`
            : "Children"
        }
        action={
          fullScreen ? (
            <Stack direction="row" spacing={2}>
              <IconButton
                color="primary"
                onClick={() => setOpenRemoveDialog(true)}
                disabled={selected.length === 0}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<DeleteIcon />}
                variant="text"
                onClick={() => setOpenRemoveDialog(true)}
                disabled={selected.length === 0}
              >
                Remove
              </Button>
            </Stack>
          )
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={items}
          columns={columns}
          {...standardProps}
          pageSize={10}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          onRowClick={handleRowClick}
          loading={loading}
          checkboxSelection
          disableSelectionOnClick={true}
          onSelectionModelChange={(selectionModel) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
        />
      )}

      {openRemoveDialog && (
        <RemoveChildDialog
          open={true}
          handleClose={(canceled) => {
            setOpenRemoveDialog(false);
            if (!canceled) setSelected([]);
          }}
          station={station}
          selected={selected}
        />
      )}
    </Card>
  );
}

export default ChildrenCard;
