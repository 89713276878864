import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import type { ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";

import { THEMES } from "../constants";
import useAuth from "../hooks/useAuth";
import useFirebase from "../hooks/useFirebase";

const loadFromLocalStorage = (): string => {
  const storedTheme = localStorage.getItem("theme");
  if (storedTheme && storedTheme !== "undefined") {
    return JSON.parse(storedTheme);
  }
  return initialState.theme;
};

const initialState = {
  theme: THEMES.DAY,
  setTheme: (theme: string) => {
    // do nothing
  },
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const { database } = useFirebase();

  const [theme, _setTheme] = useState(loadFromLocalStorage);

  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      return onSnapshot(doc(database, "admins", userId), (doc) => {
        const data = doc.data();
        if (data && data.theme) {
          localStorage.setItem("theme", JSON.stringify(data.theme));
          _setTheme(data.theme);
        } else {
          _setTheme("NIGHT");
        }
      });
    }
  }, [userId, database]);

  const setTheme = useCallback(
    async (theme: string) => {
      if (userId) {
        await updateDoc(doc(database, "admins", userId), { theme });
      } else {
        localStorage.setItem("theme", JSON.stringify(theme));
        _setTheme(theme);
      }
    },
    [userId, database]
  );

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
