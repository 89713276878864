import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import { StationConnectorPip as ConnectorPip } from "../../components/Pip";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Connector, Station } from "../../types/station.db";
import { compareConnectors } from "../../util/comparators";

import AddConnectorDialog from "./AddConnectorDialog";
import EditConnectorDialog from "./EditConnectorDialog";
import RemoveConnectorDialog from "./RemoveConnectorDialog";

const columns: GridColumns<Connector> = [
  {
    field: "color",
    headerName: "Connector",
    ...columnTypes.flexDefault,
    renderCell: (params) => <ConnectorPip connector={params.row} />,
    sortable: false,
  },
  {
    field: "label",
    headerName: "Label",
    ...columnTypes.flexDefault,
    valueFormatter: (params) => params.value ?? "-",
  },
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexDefault,
  },
  {
    field: "power",
    headerName: "Power",
    ...columnTypes.flexNumber,
    valueFormatter: (params) => {
      const kw = params.value / 1000;
      const formatted = kw > 21 ? Math.round(kw) : kw.toFixed(1);
      return `${formatted} kW`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    ...columnTypes.flexDefault,
    renderCell: (params) => {
      if (params.row.offline) {
        return (
          <Chip
            label={"Offline"}
            variant={"outlined"}
            color={"error"}
            size={"small"}
          />
        );
      } else {
        return (
          <Chip
            label={"No Status"}
            variant={"outlined"}
            color={"info"}
            size={"small"}
          />
        );
      }
    },
    sortable: false,
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function ConnectorsCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const items = useMemo<Connector[]>(
    () => Object.values(station.connectors).sort(compareConnectors),
    [station]
  );

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isEmpty = items.length === 0;
  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          items.length > 0
            ? `Connectors (${numberFormatter.format(items.length)})`
            : "Connectors"
        }
        action={
          fullScreen ? (
            <Stack direction="row" spacing={2}>
              <IconButton
                color="primary"
                onClick={() => setOpenAddDialog(true)}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => setOpenEditDialog(true)}
                disabled={selected.length === 0}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => setOpenRemoveDialog(true)}
                disabled={selected.length === 0}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<AddIcon />}
                variant="text"
                onClick={() => setOpenAddDialog(true)}
              >
                Add
              </Button>
              <Button
                startIcon={<EditIcon />}
                variant="text"
                onClick={() => setOpenEditDialog(true)}
                disabled={selected.length === 0}
              >
                Edit
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                variant="text"
                onClick={() => setOpenRemoveDialog(true)}
                disabled={selected.length === 0}
              >
                Remove
              </Button>
            </Stack>
          )
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={items}
          columns={columns}
          {...standardProps}
          pageSize={10}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          columnVisibilityModel={{
            label: items.some((c) => c.label),
          }}
          checkboxSelection
          disableSelectionOnClick={false}
          onSelectionModelChange={(selectionModel) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
        />
      )}

      {openAddDialog && (
        <AddConnectorDialog
          open={true}
          handleClose={() => setOpenAddDialog(false)}
          station={station}
        />
      )}
      {openEditDialog && (
        <EditConnectorDialog
          open={true}
          handleClose={(canceled) => {
            setOpenEditDialog(false);
            if (!canceled) setSelected([]);
          }}
          station={station}
          selected={selected}
        />
      )}
      {openRemoveDialog && (
        <RemoveConnectorDialog
          open={true}
          handleClose={(canceled) => {
            setOpenRemoveDialog(false);
            if (!canceled) setSelected([]);
          }}
          station={station}
          selected={selected}
        />
      )}
    </Card>
  );
}

export default ConnectorsCard;
