import {
  collection,
  deleteField,
  doc,
  getFirestore,
  writeBatch,
} from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Remove the selected children from the parent station.
 *
 * @param station The parent station.
 * @param selected The children to remove.
 */
const removeStationParent = async ({
  station,
  selected,
}: {
  station: WithDocRef<Station>;
  selected: string[];
}) => {
  const db = getFirestore();
  const batch = writeBatch(db);
  const collectionRef = collection(db, "stations");

  const docData: any = {};
  for (const id of selected) {
    const childRef = doc(collectionRef, id);
    batch.update(childRef, { parent_id: deleteField(), ...updatedAt });

    // Accumulate parent updates
    docData[`children.${id}`] = deleteField();
  }
  batch.update(station.docRef, { ...docData, ...updatedAt });
  await batch.commit();
};

export default removeStationParent;
