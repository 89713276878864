import { MoreVert as MoreIcon } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { EditZipcodesDialog } from "../components/dialogs/EditZipcodesDialog";
import Loader from "../components/Loader";
import { ManageImageCard } from "../components/ManageImageCard";
import { PageHeader } from "../components/PageHeader";
import { StatCard, StatCardItem } from "../components/StatCard";
import ZipcodesCard from "../components/ZipcodesCard";
import useDoc from "../hooks/useDoc";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import type { Utility } from "../types/utility.db";
import {
  FirestoreMenuItem,
  getPathFromUrl,
  getPublicUrl,
  isStorageUrl,
  updatedAt,
} from "../util/firebase";
import { centsToDollars, parseCurrency } from "../util/stdlib";

import Page404 from "./auth/Page404";
import { EditRatesDialog } from "./utilities/EditRatesDialog";
import EditUtilityDialog from "./utilities/EditUtilityDialog";

function MenuButton({
  utility,
}: {
  utility: WithDocRef<Utility>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database } = useFirebase();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);
  const collectionRef = collection(database, "electric-utilities");
  const docRef = doc(collectionRef, utility.id);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "profile-menu" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={utility.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemText inset>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemText inset>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <EditUtilityDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        fullScreen={fullScreen}
        utility={utility}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={docRef}
        displayName={utility.name}
      />
    </React.Fragment>
  );
}

function UtilityProfile(): JSX.Element {
  const { utilityId } = useParams();
  const [utility, loading] = useDoc<Utility>("electric-utilities", utilityId!);
  const [uploading, setUploading] = useState(false);

  const { storage } = useFirebase();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [openEditRatesDialog, setOpenEditRatesDialog] = useState(false);
  const [openEditZipcodesDialog, setOpenEditZipcodesDialog] = useState(false);

  const uploadLogo = useCallback(
    async (files) => {
      try {
        setUploading(true);

        const logoUrl = utility?.logo_url;
        if (logoUrl && isStorageUrl(logoUrl)) {
          const storageRef = ref(storage.public, getPathFromUrl(logoUrl));
          await deleteObject(storageRef);
        }

        const first = files[0];
        const storageRef = ref(
          storage.public,
          ["img", "utilities", utility!.id, first.name].join("/")
        );

        const snapshot = await uploadBytes(storageRef, first);
        await updateDoc(utility!.docRef, {
          logo_url: getPublicUrl(snapshot),
          ...updatedAt,
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to upload image.", { variant: "error" });
      } finally {
        setUploading(false);
      }
    },
    [storage, utility, enqueueSnackbar]
  );

  const removeLogo = useCallback(async () => {
    try {
      setUploading(true);

      const logoUrl = utility?.logo_url;
      if (logoUrl && isStorageUrl(logoUrl)) {
        const storageRef = ref(storage.public, getPathFromUrl(logoUrl));
        await deleteObject(storageRef);
      }

      await updateDoc(utility!.docRef, {
        logo_url: null,
        ...updatedAt,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to remove image.", { variant: "error" });
    } finally {
      setUploading(false);
    }
  }, [storage, utility, enqueueSnackbar]);

  const updateRates = useCallback(
    async (data: any) => {
      // In whole cents
      const primary_rate = parseCurrency(data.primary_rate);
      const secondary_rate = parseCurrency(data.secondary_rate);

      await updateDoc(utility!.docRef, {
        primary_rate,
        secondary_rate,
        ...updatedAt,
      });
    },
    [utility]
  );

  const updateZipcodes = useCallback(
    async (zipcodes: string[]) => {
      await updateDoc(utility!.docRef, {
        zipcodes,
        ...updatedAt,
      });
    },
    [utility]
  );

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : utility ? (
        <>
          <PageHeader
            title={utility.name}
            action={<MenuButton utility={utility} />}
            href={utility.website_url}
            sectionIsId={true}
          />

          <Grid container spacing={fullScreen ? 4 : 6}>
            <Grid item xs={12} lg={4} xl={3}>
              <ManageImageCard
                title={"Logo"}
                imageUrl={utility.logo_url}
                onDrop={uploadLogo}
                onRemove={removeLogo}
                loading={uploading}
              />
            </Grid>

            <Grid
              item
              container
              spacing={fullScreen ? 4 : 6}
              xs={12}
              lg
              alignContent="flex-start"
            >
              <Grid item xs={12}>
                <StatCard
                  title="Rates"
                  action={
                    <Button
                      variant="text"
                      onClick={() => setOpenEditRatesDialog(true)}
                    >
                      Edit
                    </Button>
                  }
                >
                  <StatCardItem
                    value={
                      utility.primary_rate
                        ? centsToDollars(utility.primary_rate)
                        : "None"
                    }
                    caption="Primary Rate"
                  />
                  <StatCardItem
                    value={
                      utility.secondary_rate
                        ? centsToDollars(utility.secondary_rate)
                        : "None"
                    }
                    caption="Secondary Rate"
                  />
                </StatCard>
                <EditRatesDialog
                  open={openEditRatesDialog}
                  handleClose={() => setOpenEditRatesDialog(false)}
                  handleSave={updateRates}
                  fullScreen={fullScreen}
                  rates={[utility.primary_rate, utility.secondary_rate]}
                />
              </Grid>

              <Grid item xs={12}>
                <ZipcodesCard
                  zips={utility.zipcodes}
                  action={
                    <Button
                      variant="text"
                      onClick={() => setOpenEditZipcodesDialog(true)}
                    >
                      Edit
                    </Button>
                  }
                />
                <EditZipcodesDialog
                  open={openEditZipcodesDialog}
                  handleClose={() => setOpenEditZipcodesDialog(false)}
                  handleSave={updateZipcodes}
                  fullScreen={fullScreen}
                  zipcodes={utility.zipcodes}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export { UtilityProfile as default };
