import {
  Autocomplete,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { connectorNameOptions } from "../../util/chargeway";

import addStationConnector from "./firebase/addStationConnector";

function AddConnectorDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        name: null,
        power: "",
        quantity: 1,
      });
    }
  }, [open, reset]);

  const onSubmit = async (data: any) => {
    const watts = Number(data.power) * 1000;

    // Validation
    if (data.name === "J1772") {
      if (watts > 19200) {
        return setError("power", {
          message: "J1772 connectors must not exceed 19.2 kW",
        });
      }
    }

    try {
      await addStationConnector({
        station,
        name: data.name,
        power: watts,
        quantity: Number(data.quantity),
      });
      enqueueSnackbar("Success", { variant: "success" });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Add Connector"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={connectorNameOptions}
              onChange={(event, newValue) => field.onChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Type"}
                  helperText={error?.message}
                  error={Boolean(error)}
                  autoFocus
                />
              )}
              fullWidth
            />
          )}
        />

        <Controller
          name={"power"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Power"
              helperText={error?.message || ""}
              error={Boolean(error)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>kW</InputAdornment>
                ),
              }}
              fullWidth
            />
          )}
        />

        <Divider />

        <Controller
          name={"quantity"}
          control={control}
          rules={{ required: true, min: 1, max: 999 }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Quantity"
              helperText={error?.message || "The number of connectors to add"}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default AddConnectorDialog;
