import {
  Card,
  CardContent,
  CardHeader,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import {
  collection,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { StationPhoto } from "../../types/station-photo.db";
import type { Station } from "../../types/station.db";
import type { User } from "../../types/user.db";

function PhotoListItem({
  photo,
  stations,
}: {
  photo: StationPhoto;
  stations: Station[];
}): JSX.Element {
  const navigate = useNavigate();
  const station = stations.find((s) => s.id === photo.station_id);
  const createdAt = (photo.timestamps.created_at as Timestamp)?.toDate();

  const navigateOnClick = useCallback(
    (event) => {
      const _path = `/photos/${photo.id}`;
      if (event.metaKey) {
        window.open(_path, "_blank");
      } else {
        navigate(_path);
      }
    },
    [photo.id, navigate]
  );

  return (
    <ImageListItem key={photo.id}>
      <img
        src={photo.photo_url}
        alt={photo.id}
        onClick={navigateOnClick}
        style={{ cursor: "pointer" }}
        loading="lazy"
      />
      {station && (
        <ImageListItemBar
          title={
            <Link
              href={`/stations/${station.id}`}
              sx={{ color: "text.primary" }}
            >
              {station.name}
            </Link>
          }
          subtitle={formatDistanceToNowStrict(createdAt, { addSuffix: true })}
        />
      )}
    </ImageListItem>
  );
}

const listReducer = (
  state: Station[],
  action: { type: "add"; newItem: Station }
): Station[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function PhotoMosaicCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database: db } = useFirebase();
  const [items, loading] = useQuery<StationPhoto>(
    query(
      collection(db, "station-photos"),
      where("user_id", "==", user.id),
      orderBy("timestamps.created_at", "desc"),
      limit(250) // Temporary until pagination is implemented
    )
  );
  const [stations, dispatch] = useReducer(listReducer, []);

  // Load stations
  useEffect(() => {
    const unique = [...new Set(items.map((item) => item.station_id))];
    for (const stationId of unique) {
      getDoc(doc(db, "stations", stationId)).then((doc) => {
        if (doc.exists()) {
          const station = { id: doc.id, ...doc.data() } as Station;
          dispatch({ type: "add", newItem: station });
        }
      });
    }
  }, [db, items]);

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          (user?.photo_count ?? 0) > 1
            ? `Photos (${numberFormatter.format(user.photo_count ?? 0)})`
            : "Photos"
        }
      />
      {items.length > 0 && (
        <CardContent>
          <ImageList variant="masonry" cols={fullScreen ? 1 : 3} gap={2}>
            {items.map((item) => (
              <PhotoListItem key={item.id} photo={item} stations={stations} />
            ))}
          </ImageList>
        </CardContent>
      )}
    </Card>
  );
}

export default PhotoMosaicCard;
