import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import {
  arrayRemove,
  arrayUnion,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle, VehicleCapability } from "../../types/vehicle.db";

function CapabilitiesCard({
  vehicle,
}: {
  vehicle: WithDocRef<Vehicle>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [capabilities, setCapabilities] = useState(vehicle.capabilities);

  useEffect(() => {
    setCapabilities(vehicle.capabilities);
  }, [vehicle]);

  const updateCapability = (
    capability: VehicleCapability,
    newValue: boolean
  ) => {
    let updated: string[];

    if (newValue) {
      updated = [...capabilities, capability];
    } else {
      updated = capabilities.filter((value) => {
        if (capability === "SMARTCAR_CONNECT") {
          return !value.startsWith("SMARTCAR");
        }
        return value !== capability;
      });
    }

    setCapabilities(updated);
    updateDoc(vehicle.docRef, {
      capabilities: updated,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update capabilities", {
          variant: "error",
        });
      });
  };

  return (
    <PartitionedCard title={"Capabilities"} spacing={2}>
      <PartitionedCardItem title={"Plug and Charge"} alignItems={"center"}>
        <Switch
          checked={capabilities.includes("PLUG_AND_CHARGE")}
          onChange={(event) => {
            updateCapability("PLUG_AND_CHARGE", event.target.checked);
          }}
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Smartcar"}
        caption={"Connect"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Switch
            checked={capabilities.includes("SMARTCAR_CONNECT")}
            onChange={(event) => {
              updateCapability("SMARTCAR_CONNECT", event.target.checked);
            }}
          />

          <Tooltip
            title={"Allow drivers to link this vehicle via Smartcar."}
            placement={"top"}
            arrow
          >
            <InfoIcon color={"primary"} />
          </Tooltip>
        </Stack>
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Smartcar"}
        caption={"Start & stop charge"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Switch
            checked={capabilities.includes("SMARTCAR_CONTROL_CHARGE")}
            onChange={(event) => {
              updateCapability("SMARTCAR_CONTROL_CHARGE", event.target.checked);
            }}
            disabled={!capabilities.includes("SMARTCAR_CONNECT")}
          />

          <Tooltip
            title={
              "Allow drivers to remotely start and stop charging via Smartcar. Not supported by all makes and models. Review the list of Smartcar compatible vehicles."
            }
            placement={"top"}
            arrow
          >
            <InfoIcon color={"primary"} />
          </Tooltip>
        </Stack>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default CapabilitiesCard;
