import {
  collection,
  doc,
  getFirestore,
  increment,
  writeBatch,
} from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Update the network of this station and network station counts.
 */
const updateNetwork = async ({
  station,
  network_id,
}: {
  station: WithDocRef<Station>;
  network_id: string;
}) => {
  const db = getFirestore();
  const collectionRef = collection(db, "networks");
  const fromNetwork = doc(collectionRef, station.network_id);
  const toNetwork = doc(collectionRef, network_id);

  const batch = writeBatch(db);
  batch.update(fromNetwork, { station_count: increment(-1) });
  batch.update(toNetwork, { station_count: increment(1) });
  batch.update(station.docRef, { network_id, ...updatedAt });
  await batch.commit();
};

export default updateNetwork;
