import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";

import removeStationParent from "./firebase/removeStationParent";

function RemoveParentDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: (canceled?: boolean) => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const removeParent = async () => {
    try {
      await removeStationParent({ station });
      enqueueSnackbar("Success", { variant: "success" });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true)}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Remove parent?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)}>Cancel</Button>
        <Button onClick={removeParent}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveParentDialog;
