import {
  collection,
  deleteField,
  doc,
  getFirestore,
  writeBatch,
} from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Remove the parent from this child station.
 *
 * @param station
 */
const removeStationParent = async ({
  station,
}: {
  station: WithDocRef<Station>;
}) => {
  if (!station.parent_id) throw Error("Station has no parent to remove");

  const db = getFirestore();
  const collectionRef = collection(db, "stations");
  const parentRef = doc(collectionRef, station.parent_id);

  const batch = writeBatch(db);
  batch.update(parentRef, {
    [`children.${station.id}`]: deleteField(),
    ...updatedAt,
  });
  batch.update(station.docRef, {
    parent_id: deleteField(),
    ...updatedAt,
  });
  await batch.commit();
};

export default removeStationParent;
