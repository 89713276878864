import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import useLoading from "../../hooks/useLoading";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";

import updateNetwork from "./firebase/updateNetwork";

function EditNetworkDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [networks, setNetworks] = useState<WithDocRef<Network>[]>([]);
  const [loading, setLoading] = useLoading();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({ network: null });
    }
  }, [open, reset]);

  // Load networks
  useEffect(() => {
    if (open) {
      const db = getFirestore();
      const q = query(collection(db, "networks"), where("publish", "==", true));

      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          const data = snapshot.docs.map((doc) => {
            return { id: doc.id, docRef: doc.ref, ...doc.data() };
          }) as WithDocRef<Network>[];
          data.sort((a, b) => a.name.localeCompare(b.name));
          setNetworks(data);
          setValue(
            "network",
            data.find((n) => n.id === station.network_id)
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, setLoading, setValue, station]);

  const onSubmit = async (data: any) => {
    try {
      await updateNetwork({ station, network_id: data.network.id });
      enqueueSnackbar("Success", { variant: "success" });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Network"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack spacing={6} mt={2}>
        <Controller
          name={"network"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={networks}
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => value.name}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              onChange={(event, newValue) => field.onChange(newValue)}
              fullWidth
              loading={loading}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={error?.message}
                  error={Boolean(error)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditNetworkDialog;
