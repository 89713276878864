import { searchClient } from "@algolia/client-search";
import { Add as AddIcon, AutoMode as MergeIcon } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
  highlightResultGetter,
  renderHighlightResult,
} from "../algolia/helpers";
import type { StationHit } from "../algolia/types";
import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import { PageHeader } from "../components/PageHeader";
import useAuth from "../hooks/useAuth";
import useLoading from "../hooks/useLoading";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import type { SavedState } from "../types/navigate";
import { formatStationAddress } from "../util/stdlib";

import AddStationDialog from "./stations/AddStationDialog";
import MergeStationDialog from "./stations/MergeStationDialog";

const indexName = "stations";
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID!;

const renderStationNameAddress = (
  params: GridRenderCellParams<any, StationHit>
) => {
  const row = params.row;
  const name = row._highlightResult?.name?.value ?? row.name;
  const address = row._highlightResult?.address?.value ?? row.address;
  return (
    <Stack>
      <Typography dangerouslySetInnerHTML={{ __html: name }}></Typography>
      <Typography variant={"caption"} color={"text.secondary"}>
        {formatStationAddress(params.row)}
      </Typography>
    </Stack>
  );
};

const renderStatusChip = (params: GridRenderCellParams<any, StationHit>) => {
  if (params.row.is_coming_soon) {
    return (
      <Chip
        label={"Coming Soon"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (params.row.is_offline) {
    return (
      <Chip
        label={"Offline"}
        variant={"outlined"}
        color={"error"}
        size={"small"}
      />
    );
  }
  return (
    <Chip
      label={"Online"}
      variant={"outlined"}
      color={"success"}
      size={"small"}
    />
  );
};

const renderPublishedChip = (params: GridRenderCellParams<any, StationHit>) => {
  if (params.row.archived) {
    return (
      <Chip
        label={"Archived"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (!params.row.publish) {
    return (
      <Chip
        label={"Not Published"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
  if (params.row.publish) {
    return (
      <Chip
        label={"Published"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  }
};

const columns: GridColumns<StationHit> = [
  {
    field: "nrel_id",
    headerName: "NREL ID",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: highlightResultGetter,
    renderCell: renderHighlightResult,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexPrimary,
    minWidth: 350,
    renderCell: renderStationNameAddress,
    sortable: false,
  },
  // {
  //   field: "address",
  //   headerName: "Address",
  //   ...columnTypes.flexDefault,
  //   minWidth: 250,
  //   valueGetter: highlightResultGetter,
  //   renderCell: renderHighlightResult,
  //   sortable: false,
  // },
  // {
  //   field: "city",
  //   headerName: "City",
  //   ...columnTypes.flexDefault,
  //   valueGetter: highlightResultGetter,
  //   renderCell: renderHighlightResult,
  //   sortable: false,
  // },
  {
    field: "state",
    headerName: "State",
    ...columnTypes.flexDefault,
    valueGetter: highlightResultGetter,
    renderCell: renderHighlightResult,
    sortable: false,
  },
  // {
  //   field: "postal_code",
  //   headerName: "Postal code",
  //   ...columnTypes.flexDefault,
  //   valueGetter: highlightResultGetter,
  //   renderCell: renderHighlightResult,
  //   sortable: false,
  // },
  {
    field: "network_name",
    headerName: "Network",
    ...columnTypes.flexDefault,
    minWidth: 200,
    valueGetter: highlightResultGetter,
    renderCell: renderHighlightResult,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    ...columnTypes.flexDefault,
    renderCell: renderStatusChip,
    sortable: false,
  },
  {
    field: "published",
    headerName: "Published",
    ...columnTypes.flexDefault,
    renderCell: renderPublishedChip,
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
    valueGetter: (params) => new Date(params.row.timestamps.created_at * 1000),
    sortable: false,
  },
];

type StationsSavedState = SavedState & {
  search_filters: string;
  show_archived: boolean;
};

function Stations(): JSX.Element {
  const { searchApiKey } = useAuth();
  const client = useMemo(
    () => (searchApiKey ? searchClient(algoliaAppId, searchApiKey) : null),
    [searchApiKey]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [savedState, setSavedState] = useState(
    (location?.state as StationsSavedState) || {}
  );
  const [page, setPage] = useState(0);
  const [hits, setHits] = useState<any[]>([]);
  const [search, setSearch] = useState<string>(savedState?.search ?? "");
  const [searchFilters, setSearchFilters] = useState<string>(
    savedState?.search_filters ?? ""
  );
  const [showArchived, setShowArchived] = useState(
    savedState?.show_archived ?? false
  );
  const [debounced] = useDebounce(search, 200);
  const [loading, setLoading] = useLoading();
  const handleRowClick = useNavigateOnRowClick();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({
      page: page,
      search: search,
      search_filters: searchFilters,
      show_archived: showArchived,
    });
  }, [page, search, searchFilters, showArchived]);

  // Update search results on quick search input
  useEffect(() => {
    if (!debounced) {
      return setHits([]);
    }
    if (!client) {
      return console.warn("SearchClient not initialized");
    }

    setLoading(true);
    client
      .searchSingleIndex({
        indexName,
        searchParams: {
          query: debounced,
          filters: searchFilters, // e.g. "archived:false"
          hitsPerPage: 20,
        },
      })
      .then((results) => {
        setHits(results.hits);
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced, searchFilters, client, setLoading]);

  // Update search filters
  useEffect(() => {
    if (!showArchived) {
      setSearchFilters("archived:false");
    } else {
      setSearchFilters("archived:true");
    }
  }, [showArchived]);

  return (
    <React.Fragment>
      <PageHeader
        title={"Stations"}
        action={
          <>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              fullWidth
              onClick={() => setOpenAddDialog(true)}
            >
              Add
            </Button>{" "}
            <Button
              startIcon={<MergeIcon />}
              variant="contained"
              fullWidth
              onClick={() => setOpenMergeDialog(true)}
              disabled={selected.length < 2}
            >
              Merge
            </Button>
          </>
        }
      />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <QuickSearch value={search} onChange={setSearch} />

          {/* TODO: Temporary styling for Filters. Do something better. */}
          <Card sx={{ marginBottom: 4 }}>
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ paddingX: 4, paddingY: 1, alignItems: "center" }}
            >
              <Typography variant={"subtitle2"} color={"text.secondary"}>
                Filter by:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showArchived}
                    onChange={(event) => {
                      setShowArchived(event.target.checked);
                    }}
                  />
                }
                label="Archived"
                labelPlacement={"start"}
              />
            </Stack>
          </Card>

          <DataGridCard>
            <DataGrid
              rows={hits}
              columns={columns}
              {...standardProps}
              sx={{
                ".MuiDataGrid-cell em": {
                  // color: "secondary.contrastText",
                  // backgroundColor: "secondary.main",
                  fontStyle: "normal",
                  // fontWeight: "bold",
                },
                ".MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              page={page}
              onPageChange={setPage}
              onRowClick={handleRowClick}
              getRowId={(row) => row.objectID}
              loading={loading}
              checkboxSelection
              disableSelectionOnClick={true}
              keepNonExistentRowsSelected={false}
              onSelectionModelChange={(selectionModel) =>
                setSelected(selectionModel as string[])
              }
              selectionModel={selected}
            />
          </DataGridCard>
        </Grid>
      </Grid>

      {openAddDialog && (
        <AddStationDialog
          open={true}
          handleClose={() => setOpenAddDialog(false)}
        />
      )}
      {openMergeDialog && (
        <MergeStationDialog
          open={true}
          handleClose={() => setOpenMergeDialog(false)}
          selected={selected}
        />
      )}
    </React.Fragment>
  );
}

export default Stations;
