import { serverTimestamp, updateDoc } from "firebase/firestore";
import { customAlphabet } from "nanoid";
import { alphanumeric } from "nanoid-dictionary";

import type { WithDocRef } from "../../../hooks/useQuery";
import type {
  Connector,
  ConnectorName,
  Station,
} from "../../../types/station.db";
import {
  calculateChargewayLevel,
  colorFromName,
  voltageFromLevel,
} from "../../../util/chargeway";
import { updatedAt } from "../../../util/firebase";

const firestoreRecordId = customAlphabet(alphanumeric, 20);

/**
 * Add a new connector to the given station.
 *
 * @param station
 * @param name The connector type.
 * @param power The connector power in watts.
 * @param quantity The number of connectors to create.
 */
const addStationConnector = async ({
  station,
  name,
  power,
  quantity,
}: {
  station: WithDocRef<Station>;
  name: ConnectorName;
  power: number;
  quantity: number;
}) => {
  if (name === "J1772" && power > 19200) {
    throw Error("J1772 connectors must not exceed 19.2 kW");
  }
  const level = calculateChargewayLevel(power);
  const color = colorFromName(name);
  const voltage = voltageFromLevel(level);
  const powerType = level > 2 ? "DC" : "AC";

  // Build new connectors
  const connectors: Connector[] = [];
  for (let i = 0; i < quantity; i++) {
    const key = firestoreRecordId();

    connectors.push({
      id: key,
      label: null,
      name,
      power_type: powerType,
      power: power,
      voltage,
      amperage: null, // Unused
      power_at: null,
      color,
      level,
      network_connector_id: null,
      offline: false,
      timestamps: {
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      },
    });
  }

  const docData: any = {};
  for (const value of connectors) {
    docData[`connectors.${value.id}`] = value;
  }
  await updateDoc(station.docRef, {
    ...docData,
    ...updatedAt,
  });
};

export default addStationConnector;
