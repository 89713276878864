import {
  Autocomplete,
  CircularProgress,
  Collapse,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  documentId,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormDialog from "../../components/dialogs/FormDialog";
import {
  validateCoordinates,
  validateCountryCode,
  validatePostalCode,
} from "../../forms/validation";
import useLoading from "../../hooks/useLoading";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import type { ChildStation, Connector, Station } from "../../types/station.db";
import { parseLatLng } from "../../util/stdlib";

import addStation from "./firebase/addStation";

function MergeStationDialog({
  open,
  handleClose,
  selected,
}: {
  open: boolean;
  handleClose: () => void;
  selected: string[];
}): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [networks, setNetworks] = useState<WithDocRef<Network>[]>([]);
  const [stations, setStations] = useState<WithDocRef<Station>[]>([]);
  const [loading, setLoading] = useLoading();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        network: null,
        name: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        coordinates: "",
      });
    }
  }, [open, reset]);

  // Load networks
  useEffect(() => {
    if (open) {
      const db = getFirestore();
      const q = query(collection(db, "networks"), where("publish", "==", true));

      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          const data = snapshot.docs.map((doc) => {
            return { id: doc.id, docRef: doc.ref, ...doc.data() };
          }) as WithDocRef<Network>[];
          data.sort((a, b) => a.name.localeCompare(b.name));
          setNetworks(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, setLoading]);

  // Load children
  useEffect(() => {
    if (open) {
      const db = getFirestore();
      const q = query(
        collection(db, "stations"),
        where(documentId(), "in", selected) // Note: Limited to 30 entries
      );

      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          const data = snapshot.docs.map((doc) => {
            return { id: doc.id, docRef: doc.ref, ...doc.data() };
          }) as WithDocRef<Station>[];
          setStations(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, selected, setLoading]);

  // Populate fields
  useEffect(() => {
    if (open && !loading) {
      const first = stations[0];
      const networkId = stations.every((s) => s.network_id === first.network_id)
        ? first.network_id
        : null;

      const network = networkId
        ? networks.find((n) => n.id === networkId)
        : null;
      setValue("network", network);

      const name = stations.every((s) => s.name === first.name)
        ? first.name
        : "";
      setValue("name", name);

      const address = stations.every((s) => s.address === first.address)
        ? first.address
        : "";
      setValue("address", address);

      const city = stations.every((s) => s.city === first.city)
        ? first.city
        : "";
      setValue("city", city);

      const state = stations.every((s) => s.state === first.state)
        ? first.state
        : "";
      setValue("state", state);

      const postal_code = stations.every(
        (s) => s.postal_code === first.postal_code
      )
        ? first.postal_code
        : "";
      setValue("postal_code", postal_code);

      const country = stations.every((s) => s.country === first.country)
        ? first.country
        : "";
      setValue("country", country);

      const coordinates = stations.every(
        (s) => s.latitude === first.latitude && s.longitude === first.longitude
      )
        ? [first.latitude, first.longitude].join(",")
        : "";
      setValue("coordinates", coordinates);
    }
  }, [open, networks, stations, loading, setValue]);

  const onSubmit = async (data: any) => {
    try {
      const [latitude, longitude] = parseLatLng(data.coordinates);
      const connectors: Record<string, Connector> = {};
      const children: Record<string, ChildStation> = {};

      for (const s of Object.values(stations)) {
        Object.values(s.connectors || {}).forEach((c) => {
          connectors[c.id] = c;
        });
        children[s.id] = {
          id: s.id,
          nrel_id: s.nrel_id || null,
          network_station_id: s.network_station_id || null,
        };
      }

      const docRef = await addStation({
        network: data.network,
        name: data.name.trim(),
        address: data.address.trim(),
        city: data.city.trim(),
        state: data.state.trim(),
        postal_code: data.postal_code.trim(),
        country: data.country.trim(),
        latitude,
        longitude,
        connectors,
        children,
      });

      // Finish
      navigate(`/stations/${docRef.id}`);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to add station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Merge"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Collapse in={!loading}>
        <Stack mt={2} spacing={6}>
          <Typography variant={"subtitle2"} color={"text.secondary"}>
            Create a new aggregate record and unpublish {selected.length}{" "}
            selected stations.
          </Typography>
          <Controller
            name={"network"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                autoHighlight
                autoSelect
                options={networks}
                getOptionKey={(value) => value.id}
                getOptionLabel={(value) => value.name}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                onChange={(event, newValue) => field.onChange(newValue)}
                fullWidth
                loading={loading}
                disabled={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Network"
                    helperText={error?.message}
                    error={Boolean(error)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
          <Divider />
          <Controller
            name={"name"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Name"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"address"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Address"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"city"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="City"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Stack direction={"row"} spacing={2}>
            <Controller
              name={"state"}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="State"
                  helperText={error?.message}
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />
            <Controller
              name={"postal_code"}
              control={control}
              rules={{ required: true, validate: validatePostalCode }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Postal Code"
                  placeholder={"97227"}
                  helperText={error?.message}
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />
            <Controller
              name={"country"}
              control={control}
              rules={{ required: true, validate: validateCountryCode }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Country"
                  helperText={error?.message}
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />
          </Stack>

          <Controller
            name={"coordinates"}
            control={control}
            rules={{ required: true, validate: validateCoordinates }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Coordinates"
                helperText={
                  error?.message ||
                  "A latitude and longitude pair separated by a comma"
                }
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
        </Stack>
      </Collapse>
    </FormDialog>
  );
}

export default MergeStationDialog;
