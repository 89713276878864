import { MoreVert as MoreIcon } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import type { Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import NotesCard from "../components/NotesCard";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { Beacon } from "../types/beacon.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import ChargerList from "./beacons/ChargerList";
import ExpiresAt from "./beacons/components/ExpiresAt";
import GeneralCard from "./beacons/GeneralCard";
import LocationCard from "./beacons/LocationCard";
import ManageLargePromoCard from "./beacons/ManageLargePromoCard";
import RebateOfferCard from "./beacons/RebateOfferCard";
import SettingsCard from "./beacons/SettingsCard";
import VehicleList from "./beacons/VehicleList";

function MoreOptionsMenu({
  beacon,
}: {
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-beacon" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-beacon"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={beacon.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemText inset>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={beacon.docRef}
        displayName={beacon.name}
      />
    </React.Fragment>
  );
}

function BeaconProfile(): JSX.Element {
  const { beaconId } = useParams();
  const [beacon, loading] = useDoc<Beacon>("beacons", beaconId!);

  const expiresAt = beacon?.timestamps.expires_at
    ? (beacon.timestamps.expires_at as Timestamp).toDate()
    : null;

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : beacon ? (
        <>
          <PageHeader
            overline={
              beacon.sponsor && (
                <Link href={`/sponsors/${beacon.sponsor?.id}`}>
                  {beacon.sponsor?.name}
                </Link>
              )
            }
            title={beacon.title}
            subtitle={beacon.name}
            action={
              <>
                {expiresAt && <ExpiresAt value={expiresAt} size={"medium"} />}
                <MoreOptionsMenu beacon={beacon} />
              </>
            }
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <GeneralCard beacon={beacon} />
                <LocationCard beacon={beacon} />
                <RebateOfferCard beacon={beacon} />
                <ManageLargePromoCard beacon={beacon} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <Stack spacing={6}>
                <SettingsCard beacon={beacon} />
                <NotesCard docRef={beacon.docRef} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <ChargerList beacon={beacon} />
            </Grid>

            <Grid item xs={12}>
              <VehicleList beacon={beacon} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default BeaconProfile;
