import { LaunchRounded } from "@mui/icons-material";
import { Button, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { formatStationAddress } from "../../util/stdlib";

import EditLocationDialog from "./EditLocationDialog";

function LocationCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    formatStationAddress(station) ?? ""
  )}`;
  const latLngUrl = `https://www.google.com/maps/search/?api=1&query=${station.latitude}%2C${station.longitude}`;

  return (
    <>
      <PartitionedCard
        title={"Location"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Address"} alignItems={"center"}>
          <Typography flexGrow={"1"} alignSelf={"center"}>
            {formatStationAddress(station)}
          </Typography>
          <IconButton
            component={Link}
            href={addressUrl}
            target={"_blank"}
            title={"View in Google Maps"}
          >
            <LaunchRounded fontSize="small" />
          </IconButton>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Coordinates"} alignItems={"center"}>
          <Typography flexGrow={"1"} alignSelf={"center"}>
            {station.latitude},{station.longitude}
          </Typography>
          <IconButton
            component={Link}
            href={latLngUrl}
            target={"_blank"}
            title={"View in Google Maps"}
          >
            <LaunchRounded fontSize="small" />
          </IconButton>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Time Zone"}>
          {station.time_zone || "-"}
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditLocationDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          station={station}
        />
      )}
    </>
  );
}

export default LocationCard;
