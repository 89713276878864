import { MoreVert as MoreIcon } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { Charger } from "../types/charger.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import EditChargerDialog from "./chargers/EditChargerDialog";
import ManageChargerImageCard from "./chargers/ManageChargerImageCard";

function MoreOptionsMenu({
  charger,
}: {
  charger: WithDocRef<Charger>;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-charger" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-charger"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={charger.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemText inset>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemText inset>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <EditChargerDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        charger={charger}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={charger.docRef}
        displayName={`${charger.make} ${charger.model}`}
      />
    </React.Fragment>
  );
}

function ChargerProfile(): JSX.Element {
  const { chargerId } = useParams();
  const [charger, loading] = useDoc<Charger>("home-chargers", chargerId!);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : charger ? (
        <>
          <PageHeader
            title={`${charger.make} ${charger.model}`}
            subtitle={charger.model_variant}
            href={charger.details_link}
            action={<MoreOptionsMenu charger={charger} />}
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={3}>
              <ManageChargerImageCard charger={charger} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default ChargerProfile;
