import {
  Add as AddIcon,
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import type { DocumentReference } from "firebase/firestore";
import { orderBy } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import type { TrackedUrl } from "../types/tracked.db";

import { createTrackedUrl } from "./tracked-urls";

function AddLinkDialog({
  open,
  handleClose,
  fullScreen,
}: {
  open: boolean;
  handleClose: () => void;
  fullScreen: boolean;
}): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({
        destination_url: "",
        source: "",
        medium: "",
        campaign: "",
      });
    }
  }, [open, reset]);

  const { database } = useFirebase();
  const onCancel = useCallback(() => handleClose(), [handleClose]);
  const onSubmit = useCallback(
    async (data: any) => {
      await createTrackedUrl({
        database,
        destination_url: data.destination_url,
        source: data.source,
        medium: data.medium,
        campaign: data.campaign,
      });
      handleClose();
    },
    [database, handleClose]
  );

  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>
          Add Tracked Link
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="destination_url"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^https?:\/\//,
                  message: "URL must start with http or https.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus={true}
                  label="Destination URL"
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />

            <Stack direction={"row"} spacing={4}>
              <Controller
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Source"
                    error={Boolean(error)}
                    helperText={"e.g. CWB-000055"}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="medium"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Medium"
                    error={Boolean(error)}
                    helperText={"e.g. beacon or email"}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="campaign"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Campaign"
                    error={Boolean(error)}
                    helperText={"e.g. SRP"}
                    fullWidth
                  />
                )}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function TrackedLinks(): JSX.Element {
  const { database: db } = useFirebase();

  const [items, loading] = useCollection<TrackedUrl>(
    "tracked-urls",
    orderBy("timestamps.created_at", "desc")
  );
  const [filtered, setFiltered] = useState<WithDocRef<TrackedUrl>[]>(items);
  const [search, setSearch] = useState<string>("");
  const [debounced] = useDebounce(search, 200);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [deleteRef, setDeleteRef] = useState<DocumentReference | null>(null);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) =>
            regex.test(item.ctm.source ?? "") ||
            regex.test(item.ctm.medium ?? "") ||
            regex.test(item.ctm.campaign ?? "") ||
            regex.test(item.tracked_url ?? "") ||
            regex.test(item.destination_url ?? "")
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  const onCopy = useCallback(
    (copyText: string) => {
      navigator.clipboard.writeText(copyText);
      enqueueSnackbar("Copied Tracked URL");
    },
    [enqueueSnackbar]
  );

  const columns: GridColumns<WithDocRef<TrackedUrl>> = useMemo(
    () => [
      {
        field: "tracked_url",
        headerName: "Destination",
        ...columnTypes.flexPrimary,
        minWidth: 400,
        renderCell: (params) => {
          return (
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              spacing={2}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onCopy(params.row.tracked_url);
                }}
              >
                <CopyIcon fontSize={"small"} />
              </IconButton>
              <Link href={params.row.destination_url} target={"_blank"}>
                {params.row.destination_url}
              </Link>
            </Stack>
          );
        },
      },
      {
        field: "visits",
        headerName: "Visits",
        ...columnTypes.flexNumber,
      },
      {
        field: "source",
        headerName: "Source",
        ...columnTypes.flexDefault,
        valueGetter: (params) => {
          return params.row.ctm.source;
        },
      },
      {
        field: "medium",
        headerName: "Medium",
        ...columnTypes.flexDefault,
        valueGetter: (params) => {
          return params.row.ctm.medium;
        },
      },
      {
        field: "campaign",
        headerName: "Campaign",
        ...columnTypes.flexDefault,
        valueGetter: (params) => {
          return params.row.ctm.campaign;
        },
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            label={"Delete"}
            icon={<DeleteIcon />}
            showInMenu={true}
            onClick={() => setDeleteRef(params.row.docRef)}
          />,
        ],
      },
    ],
    [onCopy]
  );

  return (
    <React.Fragment>
      <PageHeader
        title={"Tracked Links"}
        action={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            fullWidth
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />

              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                />
              </DataGridCard>
            </Grid>
          </Grid>

          <AddLinkDialog
            open={open}
            handleClose={() => setOpen(false)}
            fullScreen={fullScreen}
          />
          {deleteRef && (
            <DeleteDialog
              open={true}
              handleClose={() => setDeleteRef(null)}
              docRef={deleteRef}
              displayName={"Tracked URL"}
              navigateOnClose={false}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default TrackedLinks;
