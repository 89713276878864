import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { geohashForLocation } from "geofire-common";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Network } from "../../../types/network.db";
import type {
  ChildStation,
  Connector,
  Station,
} from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Add a new station record with all required values.
 *
 * @param network
 * @param name
 * @param address
 * @param city
 * @param state
 * @param postal_code
 * @param country ISO-3166 alpha-2
 * @param time_zone A name from the IANA time zone database (e.g. "Europe/Oslo", "Europe/Zurich")
 * @param latitude
 * @param longitude
 * @param is_access_restricted
 * @param is_coming_soon
 * @param is_magic_dock
 * @param is_offline
 * @param is_plug_and_charge
 * @param connectors
 * @param children Include children to create an aggregate station record.
 */
const addStation = async ({
  network,
  name,
  address,
  city,
  state,
  postal_code,
  country,
  time_zone = null,
  latitude,
  longitude,
  is_access_restricted = false,
  is_coming_soon = false,
  is_offline = false,
  is_plug_and_charge = false,
  connectors = {},
  children = null,
}: {
  network: WithDocRef<Network>;
  name: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  time_zone?: string | null;
  latitude: number;
  longitude: number;
  is_access_restricted?: boolean;
  is_coming_soon?: boolean;
  is_offline?: boolean;
  is_plug_and_charge?: boolean;
  connectors?: Record<string, Connector>;
  children?: Record<string, ChildStation> | null;
}) => {
  const db = getFirestore();
  const collectionRef = collection(db, "stations");
  const docRef = doc(collectionRef);
  const hasConnectors = Object.values(connectors || {}).length > 0;

  const data: Omit<Station, "id" | "timestamps"> = {
    nrel_id: null,
    khloud_id: null,
    khloud_guid: null,
    khloud_parent_id: null,

    // Network
    network_id: network.id,
    network_station_id: null,

    // Aggregate
    ...(children ? { children } : {}),

    // Address
    name,
    address,
    city,
    state,
    postal_code,
    country,
    time_zone,

    // Location
    latitude,
    longitude,
    geohash: geohashForLocation([latitude, longitude]),

    // Media
    photo_count: 0,
    review_count: 0,
    rating: 0,

    // Support
    support_phone: null,
    support_email: null,
    support_website: null,

    // Flags
    is_access_restricted,
    is_coming_soon,
    is_magic_dock: false,
    is_offline,
    is_plug_and_charge,
    is_tesla: network.name === "Tesla",
    is_tesla_destination: name.includes("Tesla Destination"),
    is_tesla_supercharger: name.includes("Tesla Supercharger"),
    needs_review: false,
    publish: hasConnectors,
    archived: false,

    // Connectors
    connectors,
  };

  const batch = writeBatch(db);
  batch.set(docRef, {
    ...data,
    timestamps: {
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    },
  });
  for (const child of Object.values(children || {})) {
    batch.update(doc(collectionRef, child.id), {
      parent_id: docRef.id,
      publish: false,
      ...updatedAt,
    });
  }
  await batch.commit();
  return docRef;
};

export default addStation;
