import { LaunchRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Link,
  Rating,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";

import EditStationDialog from "./EditStationDialog";

function GeneralCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const children = Object.values(station.children || {});
  const multiNrelId =
    children.filter((c) => {
      return !!c.nrel_id;
    }).length > 0;
  const multiNetworkId =
    children.filter((c) => {
      return !!c.network_station_id;
    }).length > 0;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (station.timestamps.created_at as Timestamp)?.toDate();
  const updatedAt = (station.timestamps.updated_at as Timestamp)?.toDate();

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <>
      <PartitionedCard
        title={"General"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Name"}>{station.name}</PartitionedCardItem>

        {station.parent_id && (
          <PartitionedCardItem title={"Parent ID"}>
            <Link href={`/stations/${station.parent_id}`}>
              {station.parent_id}
            </Link>
          </PartitionedCardItem>
        )}

        {children.length > 0 && (
          <PartitionedCardItem title={"Children"}>
            {numberFormatter.format(children.length)}
          </PartitionedCardItem>
        )}

        {multiNrelId ? (
          <PartitionedCardItem title={"NREL ID"} alignItems={"baseline"}>
            <Typography flexGrow={"1"} whiteSpace={"pre"}>
              {children
                .map((c) => c.nrel_id)
                .filter((value) => !!value)
                .sort()
                .join("\n")}
            </Typography>
          </PartitionedCardItem>
        ) : (
          <PartitionedCardItem title={"NREL ID"} alignItems={"center"}>
            <Typography flexGrow={"1"} alignSelf={"center"}>
              {station.nrel_id ?? "-"}
            </Typography>
            <IconButton
              component={Link}
              href={`https://afdc.energy.gov/stations#/station/${station.nrel_id}`}
              target={"_blank"}
              title={"View in AFDC"}
              disabled={!station.nrel_id}
            >
              <LaunchRounded fontSize="small" />
            </IconButton>
          </PartitionedCardItem>
        )}

        {multiNetworkId ? (
          <PartitionedCardItem title={"Network ID"}>
            <Typography flexGrow={"1"} whiteSpace={"pre"}>
              {children
                .map((c) => c.network_station_id)
                .filter((value) => !!value)
                .sort()
                .join("\n")}
            </Typography>
          </PartitionedCardItem>
        ) : (
          <PartitionedCardItem title={"Network ID"}>
            {station.network_station_id ?? "-"}
          </PartitionedCardItem>
        )}

        {station.khloud_guid && (
          <PartitionedCardItem title={"KH GUID"}>
            {station.khloud_guid}
          </PartitionedCardItem>
        )}

        {station.photo_count && (
          <PartitionedCardItem title={"Photos"}>
            {numberFormatter.format(station.photo_count)}
          </PartitionedCardItem>
        )}

        {station.review_count && (
          <PartitionedCardItem title={"Reviews"}>
            {numberFormatter.format(station.review_count)}
          </PartitionedCardItem>
        )}

        <PartitionedCardItem title={"Rating"} alignItems={"center"}>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ alignItems: "center", flexWrap: "nowrap" }}
          >
            <Rating value={station.rating} precision={0.5} readOnly />
            <Typography
              component={"span"}
              variant={"body2"}
              color={"text.secondary"}
              sx={{ flexShrink: 0 }}
            >
              {station.rating.toFixed(1)}{" "}
              {station.review_count === 1
                ? "(1 review)"
                : `(${station.review_count} reviews)`}
            </Typography>
          </Stack>
        </PartitionedCardItem>

        <PartitionedCardItem title={"First Created"}>
          {createdAt ? (
            <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Last Updated"}>
          {updatedAt ? (
            <Tooltip title={format(updatedAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(updatedAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditStationDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          station={station}
        />
      )}
    </>
  );
}

export default GeneralCard;
