import { getFirestore, writeBatch } from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { ChildStation, Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Add this child to the parent station.
 *
 * @param parent
 * @param child
 */
const addStationParent = async ({
  parent,
  child,
}: {
  parent: WithDocRef<Station>;
  child: WithDocRef<Station>;
}) => {
  const stub: ChildStation = {
    id: child.id,
    nrel_id: child.nrel_id || null,
    network_station_id: child.network_station_id || null,
  };

  const db = getFirestore();
  const batch = writeBatch(db);
  batch.update(parent.docRef, {
    [`children.${child.id}`]: stub,
    ...updatedAt,
  });
  batch.update(child.docRef, {
    parent_id: parent.id,
    publish: false,
    ...updatedAt,
  });
  await batch.commit();
};

export default addStationParent;
