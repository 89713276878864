import type { ConnectorName } from "../types/station.db";
import type {
  AdapterSearchFilter,
  ConnectorType,
  PowerLevelSearchFilter,
} from "../types/vehicle.db.js";

const connectorNameOptions: ConnectorName[] = [
  "CCS",
  "CHAdeMO",
  "J1772",
  "NACS",
  "Tesla",
];

/** Returns the Chargeway power level for the given power (in watts). */
const calculateChargewayLevel = (power: number) => {
  switch (true) {
    case power < 3000:
      return 1;
    case power < 21000:
      return 2;
    case power < 31000:
      return 3;
    case power < 51000:
      return 4;
    case power < 101000:
      return 5;
    case power < 201000:
      return 6;
    default:
      return 7;
  }
};

/** Returns the nominal voltage for a Chargeway power level. */
const voltageFromLevel = (level: number): number | null => {
  switch (level) {
    case 1:
      return 120;
    case 2:
      return 240;
    default:
      return null;
  }
};

/** Returns the Chargeway color for a connector by name. */
const colorFromName = (name: ConnectorName) => {
  switch (name) {
    case "CCS":
      return "GREEN";
    case "CHAdeMO":
      return "BLUE";
    case "J1772":
      return "GREEN";
    case "NACS":
      return "RED";
    case "Tesla":
      return "RED";
  }
};

/** Map connectors to a format appropriate for search filters. */
const levelsFromConnectors = (
  connectors: ConnectorType[]
): PowerLevelSearchFilter[] => {
  // Note: The default ranges must be zero for the following code to function properly.
  const levels: PowerLevelSearchFilter[] = ["BLUE", "GREEN", "RED"].map(
    (color) =>
      ({
        color,
        range_min: 0,
        range_max: 0,
        selected_min: 0,
        selected_max: 0,
        is_enabled: false,
      } as PowerLevelSearchFilter)
  );

  connectors
    .filter((c) => !(c.is_adapter || c.is_option))
    .forEach((c) => {
      const min = c.type === "AC" ? 1 : 3;
      const max = c.type === "AC" ? 2 : 7;

      // Note: This is pretty confusing so here's a little rundown of what this does.
      //
      // The output of this function is a list of objects that let the clients build
      // the power level search filter interface. This requires looping over a vehicle's
      // connectors to determine the range for a particular color.
      //
      // For example, a vehicle might have a GREEN AC connector that's levels 1-2 and a
      // GREEN DC connector that's levels 3-7.
      //
      // We need to merge those together into a single range of 1-7.

      const indexOf = levels.findIndex((v) => v.color === c.color);
      const oldMin = levels[indexOf]?.range_min ?? 0;
      const oldMax = levels[indexOf]?.range_max ?? 0;
      const newMin = min < oldMin || oldMin === 0 ? min : oldMin;
      const newMax = max > oldMax || oldMax === 0 ? max : oldMax;

      const update = {
        color: c.color,
        range_min: newMin,
        range_max: newMax,
        selected_min: newMin,
        selected_max: newMax,
        is_enabled: true,
      };
      if (indexOf !== -1) {
        levels[indexOf] = update;
      } else {
        levels.push(update);
      }
    });
  return levels;
};

/** Map adapters to a format appropriate for search filters. */
const adaptersFromConnectors = (
  connectors: ConnectorType[]
): AdapterSearchFilter[] => {
  return connectors
    .filter((c) => c.is_adapter)
    .map((c) => {
      const min = c.type === "AC" ? 1 : 3;
      const max = c.type === "AC" ? 2 : 7;
      return {
        type: c.type,
        name: c.name,
        color: c.color,
        range_min: min,
        range_max: max,
        is_enabled: false,
      };
    });
};

export {
  connectorNameOptions,
  calculateChargewayLevel,
  voltageFromLevel,
  colorFromName,
  levelsFromConnectors,
  adaptersFromConnectors,
};
